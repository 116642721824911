import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Checkbox,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import NotesIcon from "@mui/icons-material/Notes";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveIcon from "@mui/icons-material/Remove";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import "./dispensedModel.css";
import axios from "axios";
import { dispensedItemsArray } from "./dispensedItems";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  ChevronLeft,
  ChevronRight,
  ContentCopyRounded,
  ShoppingCartCheckout,
} from "@mui/icons-material";
import { BounceLoader } from "react-spinners";
import MuiButton from "@mui/material/Button";
import CustomToast from "../CustomToast";
import { Button, message, Modal } from "antd";

const DispenseModal = ({
  open,
  dispensedItems,
  onClose,
  patientId,
  handleCellChange,
  handleOpenProcedureModal,
  handleOpenTreatmentNoteModal,
  recordForNotes,
  patientIdForPreviousTreatment,
  selectedDate,
  clickedTreatmentData,
  from,
  treatmentId
}) => {

  const [updatedItems, setUpdatedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemCounters, setItemCounters] = useState({});
  const [modalLoader, setModalLoader] = useState(false);
  const [toast, setToast] = useState(null);
  const containerRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false); 


  const handleRefreshAndSortItems = () => {
    const sortedItems = dispensedItemsSortingFunction([...updatedItems]);
    setUpdatedItems(sortedItems);
  };

  useEffect(() => {
    // Calculate item counters when updated items change
    const counters = {};
    updatedItems.forEach((item) => {
      const itemName = item.name.split(" ")[0]; // Extract item name
      if (!counters[itemName]) {
        counters[itemName] = 1;
      } else {
        counters[itemName]++;
      }
    });
    setItemCounters(counters);
  }, [updatedItems]);

  useEffect(() => {
    if (open) {
      const itemsWithDetails = dispensedItems.map((item, index) => ({
        ...item,
        showInput: false,
        isFilled: !!item.selectedField,
        originalOrder: index,
        checked: item.quantity > 0 ? true : false
      }));
      setUpdatedItems(dispensedItemsSortingFunction(itemsWithDetails));
      setSearchTerm("");
    }
  }, [open, dispensedItems]);

  const handleChange = (itemData, itemName, key, value) => { 
    console.log("******* handle change ", itemData, itemName, key, value)
    const scrollTop = containerRef.current.scrollTop;

    const newItems = updatedItems.map((item) => {
      if (item.name === itemName) {
        console.warn("******* onchange item", item)
        let isFilled = key === "selectedField" ? !!value : item.isFilled;
        let updatedItem = { ...item, [key]: value, isFilled };
        if(key === "quantity" && itemData.isNew && value){
          let updatedProcedureData = { ...itemData, editItemName: false }; 
          itemData = updatedProcedureData; 
        }
        if (key === "n" ) {
          updatedItem.checked = value ? true : false;
          updatedItem.isNew= false
          updatedItem.custom= true
        }

        if (key === "quantity") {
          updatedItem.quantity = value
          updatedItem.checked = value > 0 ? true : false
          if (itemData?.isNew === true || item?.custom === true) {
            updatedItem.selectedField = itemData.selectedField
            handleCellChange(
              patientId,
              `${itemName}-selectedField`,
              itemData.selectedField,
              "userdispensed",
              itemData
            )}
            else{
            updatedItem.selectedField = value > 0 ? itemData.dosages[0] : ""
            handleCellChange(
              patientId,
              `${itemName}-selectedField`,
              value>0?item.dosages[0]:"",
              "userdispensed",
              itemData
            );
          }
        }
        if (key === "selectedField" && (!itemData.isNew || !itemData.custom)) {
          updatedItem.quantity = value ? itemData.quantity : 0;
          updatedItem.checked = value ? true : false;
          if(itemData?.editItemName !== false || (itemData.custom && itemData?.custom !== true)){
            console.log("******* this is being called")
          handleCellChange(
            patientId,
            `${itemName}-quantity`,
            updatedItem.quantity,
            "userdispensed",
            itemData
          );
        }}

        return updatedItem;
      }
      return item;
    });

    const updatedNewItems= newItems.map((item) => {
      if(item.editItemName){
        return {...item,editItemName:false}
      }
      else{
        return item
      }
      })

    const sortedItems = dispensedItemsSortingFunction(updatedNewItems);
    setUpdatedItems(sortedItems);
    // Was causing issue in adding custom item , by making it false custom item was being ignored always
    // itemData.isNew = false;
    requestAnimationFrame(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = scrollTop;
      } else {
        console.warn("containerRef.current is null, cannot set scrollTop");
      }
    });
    
    setTimeout(() => {
      handleCellChange(
        patientId,
        `${itemName}-${key}`,
        value,
        "userdispensed",
        itemData
      );
    }, 1000);
  };


  const dispensedItemsSortingFunction = (items) => {
    const filledItems = items.filter((item) => item.selectedField).sort((a, b) => a.originalOrder - b.originalOrder);
    const unfilledItems = items.filter((item) => !item.selectedField).sort((a, b) => a.originalOrder - b.originalOrder);
    return [...filledItems, ...unfilledItems];
  };


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    console.warn("********* Udpated Dispensed Items", updatedItems)
  }, [updatedItems])

  const filteredItems = updatedItems.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleInput = (itemName) => {
    const newItems = updatedItems.map((item) =>
      item.name === itemName ? { ...item, showInput: !item.showInput } : item
    );
    setUpdatedItems(newItems);
  };

  const getActiveItemsSummary = () => {
    const activeItems = updatedItems.filter((item) => item.isFilled);
    return activeItems
      .map(
        (item) =>
          `${item.name} (${item.selectedField || "N/A"}) x${item.quantity}`
      )
      .join(", ");
  };

  const handleAddItem = (itemName) => {
    const newCounter = (itemCounters[itemName] || 0) + 1;
    const newItemName = `${itemName} ${newCounter}`;

    const selectedItemIndex = updatedItems.findIndex(
      (item) => item.name === itemName
    );

    if (selectedItemIndex !== -1) {
      const newItem = {
        ...updatedItems[selectedItemIndex], // Copy the selected item
        name: newItemName, // Update the name with the counter
        selectedField: "", // Reset other fields as needed
        quantity: 0,
        isNew: true,
      };

      // Insert the new item below the selected item
      const newUpdatedItems = [
        ...updatedItems.slice(0, selectedItemIndex + 1), // Items before the selected item
        newItem, // The new item
        ...updatedItems.slice(selectedItemIndex + 1), // Items after the selected item
      ];

      setUpdatedItems(newUpdatedItems);
    }
  };

  const handleRemoveItem = (item) => {
    const newUpdatedItems = updatedItems.filter(
      (data) => data.name !== item.name
    );

    if (item && item._id) {
      // If the item has an ID, it means it's a saved item, so we need to make a delete request
      axios
        .delete(`/delete/dispenseditem/${item._id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {

          // Decrement the counter for the corresponding item type
          const itemName = item.name.split(" ")[0]; // Extracting the item name without counter
          setItemCounters((prevCounters) => {
            const newCounters = { ...prevCounters };
            if (newCounters[itemName]) {
              newCounters[itemName]--;
            }
            return newCounters;
          });
          setUpdatedItems(newUpdatedItems);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      // If the item doesn't have an ID, it means it's a newly added item, so just update the state
      setUpdatedItems(newUpdatedItems);
    }
  };

  
    useEffect(() => {
      if (shouldScroll) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
        setShouldScroll(false); 
      }
    }, [shouldScroll]); 

  const handleAddEmptyRow = () => {
    const newItem = {
      name: "", // Set a default name for the new item
      selectedField: "", // Initialize with an empty value
      quantity: 1, // Initialize with 0 quantity
      isNew: true, // Flag indicating it's a new item
      editItemName: true, // Show the input box for editing the item name
    };

    setUpdatedItems([...updatedItems, newItem]); // Add the new item to the end of the array
    setShouldScroll(true); 
  };

  const handleNext = () => {
    handleOpenTreatmentNoteModal(recordForNotes);
    onClose();
  };

  const handlePrevious = () => {
    handleOpenProcedureModal(patientId, recordForNotes);
    onClose();
  };

  const findPreviousTreatment = (treatments) => {
    let previousTreatment = null;
    let currentDate = new Date(selectedDate);
    let found = false;

    while (!found && currentDate) {
      currentDate = getPreviousDate(currentDate);
      previousTreatment = treatments.treatments.find((treatment) => {
        let treatmentDateString = new Date(treatment.date)
          .toISOString()
          .split("T")[0];

        return treatmentDateString === currentDate;
      });

      if (previousTreatment) {
        found = true;
      }
    }

    return previousTreatment;
  };

  const findCurrentTreatment = (treatments) => {
    let currentTreatment = null;
    let currentDate = new Date(selectedDate).toISOString().split("T")[0];
    let found = false;

    currentTreatment = treatments.treatments.find((treatment) => {
      let treatmentDateString = new Date(treatment.date)
        .toISOString()
        .split("T")[0];
      return treatmentDateString === currentDate;
    });

    if (currentTreatment) {
      found = true;
    }

    return currentTreatment;
  };
  const getPreviousDate = (dateString) => {
    const currentDate = new Date(dateString);
    const previousDate = new Date(currentDate);
    previousDate.setDate(previousDate.getDate() - 1);
    return previousDate.toISOString().split("T")[0];
  };
  const showToast = (variant, message) => {
    setToast({ variant, message });

    setTimeout(() => {
      setToast(null);
    }, 5000);
  };

  const handleCopyDispensedItemsFromPreviousTreatment = async () => {
    setModalLoader(true);
    try {
      // Fetch previous treatments
      const previousTreatmentsResponse = await axios.get(
        `/api/patient/${patientIdForPreviousTreatment}/last-treatments`
      );

      if (previousTreatmentsResponse.data.totalCount <= 0) {
        setModalLoader(false);
        showToast(
          "error",
          "There are no dispensed items from the previous treatment to copy from"
        );
        return;
      }

      // Filter to keep only treatments of type "Treatment"
      const treatments = previousTreatmentsResponse.data.treatments.filter(
        (treatment) => treatment.type === "Treatment"
      );

      if (treatments.length <= 0) {
        setModalLoader(false);
        showToast(
          "error",
          "There are no treatments of the required type to copy from"
        );
        return;
      }

      const treatment = findPreviousTreatment({ treatments: treatments });
      const currentTreatment = findCurrentTreatment({ treatments: treatments });

      if (currentTreatment.review == true) {
        showToast(
          "error",
          "The status of this treatment is reviewed, so labs can't be copied"
        );
        setModalLoader(false);
        return;
      }

      const previousDispensedItems = treatment.userdispensed.filter(
        (previousDispensedItem) =>
          previousDispensedItem.quantity >= 1 ||
          (previousDispensedItem.selectedField !== undefined &&
            previousDispensedItem.selectedField !== null &&
            previousDispensedItem.selectedField !== "")
      );

      if (previousDispensedItems.length <= 0) {
        setModalLoader(false);
        showToast(
          "error",
          "There are no dispensed items from the previous treatment to copy from"
        );
        return;
      }

      previousDispensedItems.forEach((item) => {
        if ("quantity" in item) {
          handleChange(item, item.name, "quantity", item.quantity);
        }
        if ("name" in item) {
          handleChange(item, item.name, "name", item.name);
        }
        if ("note" in item) {
          handleChange(item, item.name, "note", item.note);
        }
        if ("selectedField" in item) {
          handleChange(item, item.name, "selectedField", item.selectedField);
        }
      });
      const updatedItemsCopy = updatedItems.map((item) => {
        const foundItem = previousDispensedItems.find(
          (prevItem) => prevItem.name === item.name
        );
        if (foundItem) {
          return {
            ...item,
            quantity: foundItem.quantity,
            selectedField: foundItem.selectedField,
            note: foundItem.note,
          };
        }
        return item;
      });

      const itemsWithDetails = updatedItemsCopy.map((item, index) => ({
        ...item,
        showInput: false,
        isFilled: !!item.selectedField,
        originalOrder: index,
      }));
      setUpdatedItems(dispensedItemsSortingFunction(itemsWithDetails));

      setModalLoader(true);

      setModalLoader(false);

      showToast(
        "success",
        "Successfully copied dispensed items from previous treatments"
      );
    } catch (error) {
      console.error("Error fetching previous treatments:", error);
      showToast("error", "Failed to fetch previous treatments");
      setModalLoader(false);
    }
  };
  const placeOrder = (types) => {
    fetch(`/shopify/placeorder/${treatmentId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ types }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        onClose();
        toast.success("Order placed successfully");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(`Error placing order: ${error.message}`);
      });
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <div className="flex justify-between items-center">
          <DialogTitle>
            <span className="dispensedtitleStyling">
              {" "}
              <IconButton onClick={handlePrevious}>
                <ChevronLeft />
              </IconButton>
              Update Dispensed Items
              <IconButton onClick={handleNext}>
                <ChevronRight />
              </IconButton>
            </span>
          </DialogTitle>

          <Tooltip
            title={
              <h1 className="text-sm">
                Copy Dispensed Items From Previous Treatment
              </h1>
            }
            placement="right"
          >
            <IconButton
              onClick={() => {
                if (clickedTreatmentData&&clickedTreatmentData.review) {
                  return message.warning("This treatment is finalized so can't make any changes in it")
                } else {
                  handleCopyDispensedItemsFromPreviousTreatment()
                }
              }}
              sx={{ ml: "10px" }}
            >
              <ContentCopyRounded sx={{ fontSize: 30 }} />
            </IconButton>
          </Tooltip>
          <div className="flex items-center gap-4">


            <IconButton
              onClick={handleRefreshAndSortItems}
              style={{ marginLeft: "auto" }}
            >
              <RefreshIcon
                style={{
                  fontSize: 30,
                }}
              />
            </IconButton>

            {from === "fdv" && (
              <IconButton title="Send to Shopify">
                <ShoppingCartCheckout
                  onClick={() => {
                    if (!clickedTreatmentData.review) {
                            message.warning("This treatment is not finalized yet, so can't place a shopify order for it.")
                    }
                    else {
                      const modal = Modal.confirm({
                        title: 'Confirmation',
                        content: 'Are you sure you want to place this order?',
                        okText: 'Place Order',
                        cancelText: 'Cancel',  // Add the cancel button
                        onOk: () => {
                          modal.destroy()
                          placeOrder(["dispensed"])
                        },  // Wrap in an arrow function
                        onCancel: () => modal.destroy(),  // Handle cancel button click
                        okButtonProps: {
                          style: {
                            backgroundColor: '#1976d2',
                            borderColor: '#1976d2',
                            color: 'white',
                            fontWeight: 'bold',
                          },
                        },
                      });
                    }
                  }}
                  style={{
                    fontSize: 28,
                  }}
                />
              </IconButton>
            )}
          </div>
        </div>
        {modalLoader && (
          <div className="loader-backdrop">
            <BounceLoader color="#000" />
          </div>
        )}

        <div>
          {toast && (
            <CustomToast
              message={toast.message}
              variant={toast.variant}
              customStyles={{ bottom: "40px" }}
            />
          )}
          <div className="activeStylingD">{dispensedItems?.patientName}</div>
          <div>
            <input
              className="searchInput"
              placeholder="Search dispensed items..."
              value={searchTerm}
              onChange={handleSearchChange}
              margin="normal"
            />
          </div>
          <div className="activeStylingD">
            <strong>Active Items:</strong> {getActiveItemsSummary()}
          </div>
          <div className="outer-dispensed-container">
            <div className="inner-dispensed-container" ref={containerRef} >
              {filteredItems.map((item,index) => (
                <div className="divTableRow" key={item._id}>
                  <div className=" w-2/6">
                    {item.editItemName ? (
                      <input
                        className="dispensedTextInput"
                        type="text"
                        value={item.name || ""}
                        placeholder="Name"
                        onChange={(e) =>{
                            setUpdatedItems((prevState) => {
                              const updatedState = [...prevState];
                              updatedState[index] = { 
                                ...updatedState[index], 
                                name: e.target.value 
                              };
                              return updatedState;
                            });
                          }
                        }
                        
                        fullWidth
                      />
                    ) : (
                      <div className="divTableCell itemName">{item.name}</div>
                    )}
                  </div>

                  <div className="w-1/6 flex justify-center items-center">
                    <Checkbox
                      checked={item.checked || false}
                      // disabled={true}
                      onChange={(e) => {
                        if (clickedTreatmentData&&clickedTreatmentData.review) {
                          return message.warning("This treatment is finalized so can't make any changes in it")
                        }
                        else {
                          if ((item.isNew || item.custom) && (!item.selectedField || !item.name)) {
                            message.warning(`Please add ${!item.name ? 'name value':""} ${(!item.name && !item.selectedField) ? 'and dosage value' : !item.selectedField ? 'dosage value':""} in order to check the item`)
                          } 
                          else {
                            if (item.isNew) {
                              handleChange(
                                item,
                                item.name,
                                "n",
                                item.name
                              )
                            }
                            else {
                              handleChange(
                                item,
                                item.name,
                                "quantity",
                                e.target.checked ? 1 : 0
                              )
                            }
                          }
                      }}}
                    />
                  </div>

                  <div className="w-3/6 flex">
                    <div className="divTableCell contentFit">
                      {(item.editItemName || item.editItemName===false || item.custom) ? (
                        <input
                          className="dispensedTextInput ml-[20px]"
                          type="text"
                          placeholder="Dosage"
                          value={item.selectedField || ""}
                          onBlur={(e) => {
                            if ((clickedTreatmentData && clickedTreatmentData.review)) {
                              return message.warning("This treatment is finalized so can't make any changes in it")
                            }
                            else {
                              if(!item?.editItemName && !item.custom){
                              handleChange(
                                item,
                                item.name,
                                "selectedField",
                                e.target.value
                              ) }
                            }
                          }}
                          onChange={(e)=>{
                            setUpdatedItems((prevState) => {
                              const updatedState = [...prevState];
                              updatedState[index] = { 
                                ...updatedState[index], 
                                selectedField: e.target.value 
                              };
                              return updatedState;
                            });
                          }}
                          fullWidth
                        />
                      ) : (
                        <>
                          {item.dosages && item.dosages.length > 0 ? (
                            <Select
                              className="dispensedDropdownInput"
                              // value={item.selectedField || ""}
                              value={item.selectedField ? item.selectedField : item.checked ? item.dosages[0] : ""}
                              onChange={(e) => {
                                if (clickedTreatmentData&&clickedTreatmentData.review) {
                                  return message.warning("This treatment is finalized so can't make any changes in it")
                                } else {
                                  handleChange(
                                    item,
                                    item.name,
                                    "selectedField",
                                    e.target.value
                                  )
                                }
                              }
                              }
                              fullWidth
                            >
                              <MenuItem key="empty-option" value="">
                                Empty
                              </MenuItem>
                              {item.dosages &&
                                item.dosages.map((dosage, idx) => (
                                  <MenuItem key={idx} value={dosage}>
                                    {dosage}
                                  </MenuItem>
                                ))}
                            </Select>
                          ) : (
                            <div className="border border-gray-300 rounded-md pl-2 w-[190px] bg-transparent">
                              {item.selectedField}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="divTableCell contentFitthreeC">
                      {!item.editItemName && <input
                        className="dispensedNumberInput"
                        type="number"
                        value={item.quantity || 0}
                        onChange={(e) => {
                          if (clickedTreatmentData && clickedTreatmentData.review) {
                            return message.warning("This treatment is finalized so can't make any changes in it")
                          } else {
                            handleChange(item, item.name, "quantity", Number(e.target.value))
                          }
                        }
                        }
                        fullWidth
                      />}
                    </div>

                    <div className="divTableCell contentFitfourC">
                      {!item.isNew ? ( // Only render the button if the item is not new
                        !dispensedItemsArray.includes(item.name) ? ( // Check if the item name is not in the specified list
                          <IconButton onClick={() => handleRemoveItem(item)}>
                            <RemoveCircleRoundedIcon className="text-red-500" />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => {
                            if (clickedTreatmentData&&clickedTreatmentData.review) {
                              return message.warning("This treatment is finalized so can't make any changes in it")
                            } else { handleAddItem(item.name) }
                          }}>
                            <AddCircleRoundedIcon className="text-green-500" />
                          </IconButton>
                        )
                      ) : (
                        <div className="w-[30px]"></div>
                      )}
                      {item.note || item.showInput ? (
                        <input
                          className="dispensedTextInput"
                          type="text"
                          value={item.note || ""}
                          onChange={(e) => {
                            if (clickedTreatmentData&&clickedTreatmentData.review) {
                              return message.warning("This treatment is finalized so can't make any changes in it")
                            } else {
                              handleChange(item, item.name, "note", e.target.value)
                            }
                          }
                          }
                          fullWidth
                        />
                      ) : (
                       !item.isNew && <IconButton onClick={() => toggleInput(item.name)}>
                          <NotesIcon />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <DialogActions>
            <button
              className="btn-color py-2 mt-5 px-14 text-xs md:text-sm w-full md:w-auto"
              onClick={handleAddEmptyRow}
            >
              Add Custom Item
            </button>
            <button
              className="btn-color py-2 mt-5 px-14 text-xs md:text-sm w-full md:w-auto"
              onClick={onClose}
            >
              Finish
            </button>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};

export default DispenseModal;
