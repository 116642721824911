import React, { useEffect, useState } from 'react';
import { Progress, Spin, Upload, message } from 'antd';
import { DeleteFilled, InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import { IconButton } from '@mui/material';

const { Dragger } = Upload;

const DocumentsSection = ({ selectedPatient }) => {

    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const patientId = selectedPatient['_id']
    const [uploadProgress, setUploadProgress] = useState({});

    const customRequest = async (options) => {
        setLoading(true)
        const { file, onSuccess, onError, onProgress } = options;

        const formData = new FormData();
        formData.append('multipleFiles', file);
        formData.append('patientId', `${selectedPatient['_id']}`);
        formData.append('patientName', `${selectedPatient['Full Name']}`);

        try {
            const response = await axios.post('/upload-files', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percent = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                    onProgress({ percent });
                    setUploadProgress((prev) => ({
                        ...prev,
                        [file.uid]: percent,
                    }));
                },
            });

            message.success('File uploaded successfully!');
            onSuccess(response.data);
            setFiles([...response.data.attachments]);
            setLoading(false)
            setUploadProgress([]);
        } catch (error) {
            console.error('File upload failed:', error);
            message.error('File upload failed. Please try again.');
            onError(error);
        }
    };

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                setLoading(true)
                const response = await axios.get(`/fetch-files/${selectedPatient['_id']}`);
                setFiles(response.data.files);

            } catch (error) {
                console.error('Error fetching files');
            } finally {
                setLoading(false)
            }
        };

        fetchFiles();
    }, [patientId]);

    const deleteFile = async(fileId) => {

        try {
            const response = await axios.delete(`/delete-file/${selectedPatient['_id']}/${fileId}`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            message.success('File deleted successfully!');            
            setFiles((prevState) => (
                prevState.filter(file => file?.id !== fileId)
            ));
            setLoading(false)
            setUploadProgress([]);
        } catch (error) {
            console.error('File delete failed:', error);
            message.error('File delete failed. Please try again.');
        }
    }

    return (
        <>
            <Dragger
                customRequest={customRequest}
                multiple={true}
                showUploadList={false}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for single or multiple file uploads. Ensure your files are appropriate for upload.
                </p>
            </Dragger>
            <div style={{ marginTop: 20 }}>
                {Object.entries(uploadProgress).map(([fileUid, progress]) => (
                    <div key={fileUid}>
                        <p>File UID: {fileUid}</p>
                        <Progress percent={progress} />
                    </div>
                ))}
            </div>
            <div className='w-full'>
                <h2 className='text-lg font-medium my-3'>Uploaded Files</h2>
                {!loading && files.length === 0 ? (
                    <p>No files found</p>
                ) : (
                    <>
                        {/* <div className='w-full flex flex-wrap justify-stretch items-center gap-4 px-4'>
                            {files.map((file) => (

                                <a className='w-[30%] h-auto text-lg my-1 px-5 py-3 rounded-md shadow-md hover:bg-gray-50' href={file.webViewLink} target="_blank" rel="noopener noreferrer">
                                    {file.name}
                                </a>

                            ))}
                            {loading &&
                                <div className='flex my-0 mx-auto'>
                                    <Spin />
                                </div>
                            }
                        </div> */}

                            <div className="w-full grid grid-cols-3 gap-4 px-4">
                                {files.map((file) => (
                                    <div className="w-full flex items-center justify-between text-lg px-5 py-3 rounded-md shadow-md hover:bg-gray-50">
                                        <a
                                            key={file.name}
                                            href={file.webViewLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {file.name}
                                        </a>
                                        <IconButton className='hover:text-red-500' onClick={() => deleteFile(file?.id)}>
                                            <DeleteFilled />
                                        </IconButton>
                                    </div>
                                ))}    
                            </div>
                            {loading &&
                                <div className='flex w-full'>
                                    <Spin className='my-0 mx-auto' />
                                </div>
                            }
                    </>
                )}
            </div>
        </>
    );
};


export default DocumentsSection;
