import React, { useEffect, useState } from 'react';
import { Modal, Checkbox, Button } from 'antd';
import axios from 'axios';
import moment from 'moment';

const PrintModal = ({ isOpen, onClose, selectedPatient, encounters = [], soapNotes = {}, formSoapNote = false }) => {
    const [checkboxes, setCheckboxes] = useState({
        subjective: false,
        objective: false,
        assessment: false,
        plan: false,
        healthConcerns: false,
        diagnoses: false,
        familyHealthHistory: false,
        pastMedicalHistory: false,
        allergies: false,
        medications: false,
        implantableDevices: false,
        goals: false
    });
    const [emrData, setEMRData] = useState({})
    const [selectedEncounters, setSelectedEncounters] = useState({});


    const fetchEMRData = async () => {
        try {
            const response = await axios.get(`/emr/${selectedPatient._id}`)
            if (response.data) {
                setEMRData(response.data)
            }
            else {
                setEMRData({})
            }
        } catch (error) {
            console.error('Error fetching EMR data', error);
        }
    }

    useEffect(() => {
        if (selectedPatient) {
            fetchEMRData();
        }
    }, [selectedPatient])

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCheckboxes((prev) => ({
            ...prev,
            [name]: checked
        }));
    };

    const handlePrint = () => {
        let contentToPrint = "";

        // Helper function to format array fields
        const formatArrayField = (title, data) => {
            if (!data || !Array.isArray(data) || data.length === 0) return "";
            return `<strong>${title}:</strong><br/>${data
                .map((item) => `- ${item.value}`)
                .join("<br/>")}<br/><br/>`;
        };
        

        if (checkboxes.subjective && formSoapNote) {
            contentToPrint += `<strong>Subjective:</strong><br/>${soapNotes.subjective}<br/><br/>`;
        }

        if (checkboxes.objective && formSoapNote) {
            contentToPrint += `<strong>Objective:</strong><br/>${soapNotes.objective}<br/><br/>`;
        }

        if (checkboxes.assessment && formSoapNote) {
            contentToPrint += `<strong>Assessment:</strong><br/>${soapNotes.assessment}<br/><br/>`;
        }

        if (checkboxes.plan && formSoapNote) {
            contentToPrint += `<strong>Plan:</strong><br/>${soapNotes.plan}<br/><br/>`;
        }

        // Add selected fields to contentToPrint
        if (checkboxes.healthConcerns) {
            contentToPrint += formatArrayField("Health Concerns", emrData.healthConcerns);
        }

        if (checkboxes.diagnoses) {
            contentToPrint += formatArrayField("Diagnoses", emrData.diagnoses);
        }

        if (checkboxes.familyHealthHistory) {
            contentToPrint += formatArrayField(
                "Family Health History",
                emrData.familyHealthHistory
            );
        }

        if (checkboxes.pastMedicalHistory) {
            const { majorEvents, ongoingMedicalProblems, preventiveCare, socialHistory, nutritionHistory, developmentHistory } = emrData.pastMedicalHistory || {};
            contentToPrint += `<strong>Past Medical History</strong><br/>`;
            contentToPrint += formatArrayField("Major Events", majorEvents);
            contentToPrint += formatArrayField(
                "Ongoing Medical Problems",
                ongoingMedicalProblems
            );
            contentToPrint += formatArrayField("Preventive Care", preventiveCare);
            contentToPrint += formatArrayField("Social History", socialHistory);
            contentToPrint += formatArrayField(
                "Nutrition History",
                nutritionHistory
            );
            contentToPrint += formatArrayField(
                "Development History",
                developmentHistory
            );
        }

        if (checkboxes.allergies) {
            const { drug, food, environmental, medications } = emrData.allergies || {};
            contentToPrint += `<strong>Allergies:</strong><br/>`;
            if (drug) contentToPrint += `Drug: ${drug}<br/>`;
            if (food) contentToPrint += `Food: ${food}<br/>`;
            if (environmental) contentToPrint += `Environmental: ${environmental}<br/>`;
            if (medications) contentToPrint += `Medications: ${medications}<br/>`;
            contentToPrint += `<br/>`;
        }

        if (checkboxes.medications) {
            const medicationsData = emrData.medications || [];
            if (medicationsData.length > 0) {
                contentToPrint += `<strong>Medications:</strong><br/>${medicationsData
                    .map(
                        (item) =>
                            `- ${item.supplementName || "Unknown Supplement"} (${item.frequency || "Unknown Frequency"})`
                    )
                    .join("<br/>")}<br/><br/>`;
            }
        }

        if (checkboxes.implantableDevices) {
            contentToPrint += formatArrayField(
                "Implantable Devices",
                emrData.implantableDevices
            );
        }

        if (checkboxes.goals) {
            contentToPrint += formatArrayField("Goals", emrData.goals);
        }
        // Add SOAP notes for selected encounters
        encounters.forEach((encounter) => {
            if (selectedEncounters[encounter.createdAt]) {
                contentToPrint += `<strong>SOAP Note for ${moment(encounter.createdAt.split('T')[0]).format('MM-DD-YYYY')}:</strong><br/>Subjective:${encounter.subjective}<br/>Objective:${encounter.objective}<br/>Assessment:${encounter.assessment}<br/>Plan:${encounter.plan}<br/>`;
            }
        });

        // Create a new window for printing content
        const printWindow = window.open("", "", "height=600,width=800");
        printWindow.document.write("<html><body>");
        printWindow.document.write(contentToPrint);
        printWindow.document.write("</body></html>");
        printWindow.document.close();
        printWindow.print();
    };

    const handlePrintAll = () => {
        setCheckboxes((prev) => Object.fromEntries(Object.keys(prev).map(key => [key, true])));
        handleCheckAllEncounters(true);
    };

    useEffect(() => {
        if (Object.values(checkboxes).every(value => value === true)) {
            handlePrint();
        }
    }, [checkboxes]); 

    const handleEncounterCheckboxChange = (date) => {
        setSelectedEncounters((prev) => ({
            ...prev,
            [date]: !prev[date] // Toggle the selection for this encounter
        }));
    };

    const handleCheckAllEncounters = (isChecked) => {
        const allEncounters = {};
        encounters.forEach((encounter) => {
            allEncounters[encounter.createdAt] = isChecked; // Set all encounters to the value of isChecked
        });
        setSelectedEncounters(allEncounters);
    };
    return (
        <Modal
            title="Select Fields to Print"
            visible={isOpen}
            onCancel={onClose}
            footer={[
                <Button key="close" onClick={onClose}>
                    Close
                </Button>,
                <Button key="print" className='!bg-[#111828] hover:!bg-[#3d3d3d] hover:!text-white text-white border-none' onClick={handlePrint}>
                    Print
                </Button>,
                <Button key="printAll" className='!bg-[#111828] hover:!bg-[#3d3d3d] hover:!text-white text-white border-none' onClick={handlePrintAll}>
                    Print All
                </Button>,
            ]}
        >
            <div className=" relative w-full flex flex-row items-start justify-stretch gap-4 h-auto">
                {/* Left Section */}
                <div className="flex flex-col justify-center gap-4 w-3/6">
                    <div className="flex flex-col gap-3">


                        {/* Health Concerns Section */}
                        <div className="flex flex-col items-start gap-3">
                            <Checkbox
                                name="healthConcerns"
                                checked={checkboxes.healthConcerns}
                                onChange={handleCheckboxChange}
                            >
                                Health Concerns
                            </Checkbox>
                            <Checkbox
                                name="diagnoses"
                                checked={checkboxes.diagnoses}
                                onChange={handleCheckboxChange}
                            >
                                Diagnoses
                            </Checkbox>
                            <Checkbox
                                name="familyHealthHistory"
                                checked={checkboxes.familyHealthHistory}
                                onChange={handleCheckboxChange}
                            >
                                Family Health History
                            </Checkbox>
                        </div>

                        {/* Past Medical History Section */}
                        <Checkbox
                            name="pastMedicalHistory"
                            checked={checkboxes.pastMedicalHistory}
                            onChange={handleCheckboxChange}
                        >
                            Past Medical History
                        </Checkbox>


                        {/* Allergies Section */}
                        <Checkbox
                            name="allergies"
                            checked={checkboxes.allergies}
                            onChange={handleCheckboxChange}
                        >
                            Allergies
                        </Checkbox>


                        <Checkbox
                            name="medications"
                            checked={checkboxes.medications}
                            onChange={handleCheckboxChange}
                        >
                            Medications
                        </Checkbox>
                        <Checkbox
                            name="implantableDevices"
                            checked={checkboxes.implantableDevices}
                            onChange={handleCheckboxChange}
                        >
                            Implantable Devices
                        </Checkbox>
                        <Checkbox
                            name="goals"
                            checked={checkboxes.goals}
                            onChange={handleCheckboxChange}
                        >
                            Goals
                        </Checkbox>
                    </div>
                </div>

                {/* Center Divider */}
                <div className="w-[1px] bg-gray-500 h-auto"></div>
                {/* Encounters Section */}
                {encounters.length>0&&<div className="flex flex-col items-start gap-3">
                    <strong>Encounters:</strong>
                    {encounters.map((encounter) => (
                        <Checkbox
                            key={encounter.date}
                            checked={selectedEncounters[encounter.createdAt] || false}
                            onChange={() => handleEncounterCheckboxChange(encounter.createdAt)}
                        >
                            {moment(encounter.createdAt.split('T')[0]).format('MM-DD-YYYY')}
                        </Checkbox>
                    ))}
                </div>}
                {/* Right Section */}
                {formSoapNote && <div className="flex flex-col items-start gap-3 w-3/6">
                    <Checkbox
                        name="subjective"
                        checked={checkboxes.subjective}
                        onChange={handleCheckboxChange}
                    >
                        Subjective
                    </Checkbox>
                    <Checkbox
                        name="objective"
                        checked={checkboxes.objective}
                        onChange={handleCheckboxChange}
                    >
                        Objective
                    </Checkbox>
                    <Checkbox
                        name="assessment"
                        checked={checkboxes.assessment}
                        onChange={handleCheckboxChange}
                    >
                        Assessment
                    </Checkbox>
                    <Checkbox
                        name="plan"
                        checked={checkboxes.plan}
                        onChange={handleCheckboxChange}
                    >
                        Plan
                    </Checkbox>
                </div>}
            </div>

        </Modal>
    );
};

export default PrintModal;
