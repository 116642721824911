import React, { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// Import pages and components
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import TreatmentViewDisplay from "./Pages/TreatmentViewDisplay";
import TestingPage from "./Pages/TestingPage";
import TodayView from "./Pages/TodayView";
import TodayViewNew from "./Pages/TodayViewNew";
import Navbar from "./Components/Navbar";
import RoomView from "./Pages/RoomView";
import Updations from "./Components/Updations";
import AuditView from "./Components/AuditView";
import FrontDesk from "./Pages/FrontDesk";
import ExportData from "./Pages/ExportData";
import { LabTracker } from "./Pages/LabTracker";
import AddPatientForm from "./Pages/Add Patient";
import PatientSummaryView from "./Pages/PatientSummary";
import Chats from "./Chats";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import PageNotFound from "./Pages/PageNotFound";
import FiltersView from "./Pages/Filters/Info";
import TreatmentView from "./Pages/Treatmentview_FAST";
import { Provider } from "./context/DataContext";
import DraggableFloatingButtonPopup from "./Components/DraggableFloatingButtonPopup";
import NewTodayView from "./Pages/NewTodayView";
import TasksTab from "./Components/TasksTab";
import EMR from './Pages/EMR'

const RoutesWrapper = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const location = useLocation();

  // Callback to be passed to Navbar
  const handleNavbarToggle = (isOpen) => {
    setIsNavbarOpen(isOpen);
  };

  const validRoutes = [
    "/treatmentviewdisplay",    
    "/testingpage",
    "/roomview",
    "/updations",
    "/auditview",
    "/labtracker",
    "/frontdesk",
    "/exportdata",
    "/patientform",
    "/medicineinfo",
    "/patientsummary",
    "/filters",
    "/",
    "/treatmentview",
    "/newtodayview",
    "/emr",
  ];

  const dynamicRoutes = [
    /^\/treatmentview\/\w+$/,
    /^\/auditview\/\w+\/\w+$/,
    /^\/filters\/\w+$/,
    /^\/treatmentviewdisplay\/\w*$/,
  ];

  const showNavbar =
    validRoutes.includes(location.pathname) ||
    dynamicRoutes.some((pattern) => pattern.test(location.pathname));
    
  // Determine if the current path is not the login path
  // const showNavbar = location.pathname !== "/login" && location.pathname !== "/signup";

  // Determine the class for the content container based on the navbar visibility
  const contentContainerClass = showNavbar
    ? `content-container ${isNavbarOpen ? "navbar-open" : "navbar-closed"}`
    : "content-container";

  return (
    <div className="wrapper">
      {showNavbar && <Navbar onToggle={handleNavbarToggle} />}
      {/* Chats Menu Hidden */}
      {/* {location.pathname !== "/login" && <Chats />} */}
      {location.pathname !== "/login" && <DraggableFloatingButtonPopup />}    
      {location.pathname !== "/login" && <TasksTab type="floatingButton"/>}
      <div className={contentContainerClass}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route element={<ProtectedRoute />}>
            <Route
              path="/treatmentviewdisplay/:patientId2?"
              element={
                <Provider>
                  <TreatmentViewDisplay />
                </Provider>
              }
            />
            <Route
              path="/treatmentview/:patientId"
              element={
                <Provider>
                  <TreatmentView />
                </Provider>
              }
            />
            <Route
              path="/filters/:patientId"
              element={
                <Provider>
                  <FiltersView />
                </Provider>
              }
            />

            <Route path="/testingpage" element={<TestingPage />} />
            <Route path="/treatmentview" element={<TreatmentView />} />
            <Route path="/roomview" element={<RoomView />} />
            <Route
              path="/updations"
              element={
                <Provider>
                  <Updations />
                </Provider>
              }
            />
            <Route path="/auditview" element={<AuditView />} />
            <Route
              path="/auditview/:patientId/:orderId"
              element={<AuditView />}
            />
            <Route path="/labtracker" element={<LabTracker />} />
            <Route
              path="/frontdesk"
              element={
                <Provider>
                  <FrontDesk />
                </Provider>
              }
            />
            <Route path="/exportdata" element={<ExportData />} />
            <Route path="/patientform" element={<AddPatientForm />} />
            <Route path="/filters" element={<FiltersView />} />
            <Route path="/patientsummary" element={<PatientSummaryView />} />
            <Route
              path="/emr"
              element={
                <EMR />
              }
            />
            <Route
              path="/"
              element={
                <div className="todayview-bg">
                  <Provider>
                    <TodayView />
                  </Provider>
                </div>
              }
            />
            <Route
              path="/newtodayview"
              element={
                <div className="todayview-bg">
                  <Provider>
                    < TodayViewNew/>
                  </Provider>
                </div>
              }
            />
          </Route>
          


          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
  );
};

export default RoutesWrapper;
