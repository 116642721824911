import { Button } from 'antd'
import React from 'react'
import styles from '../../EMR.module.css';
import SoapNoteTemplateManagement from '../../../../Components/Updations/SoapTemplateManagement';
import { toast } from 'react-toastify';


const TemplatesSection = () => {
  const showToast = (message, type) => {
      toast(message, { type });
    };
  return (
<div className='w-full flex flex-col justify-start'>
          <h2 className="text-lg font-semibold mb-2 capitalize">Templates</h2>
        <SoapNoteTemplateManagement showToast={showToast} from="emr" styles={styles}/>
        
    </div>  )
}

export default TemplatesSection